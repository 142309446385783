
import { Component, Vue } from 'vue-property-decorator';
import CalendarsGroup from '@/modules/events/components/calendars-group.vue';
import EventsMonthListContainer from '@/modules/events/components/events-month-list-container.vue';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

@Component({
    components: {
        CalendarsGroup,
        EventsMonthListContainer,
        Tooltip,
    },
})
export default class EventsContainer extends Vue {
    isCalendarView: boolean = true;

    get eventsManagerRoute() {
        const routeBranch = this.$route.name
            ? this.$route.name.split('.')[0].replace('-home', '')
            : 'events-manager';

        return {
            name: `${routeBranch}.events-manager`,
            params: {
                ...this.$route.params,
            },
        };
    }

    toggleView() {
        this.isCalendarView = !this.isCalendarView;
    }
}
