var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"month-events"},[_c('div',{staticClass:"month-events__header"},[_c('span',{staticClass:"month-events__title"},[_vm._v(" "+_vm._s(_vm.$t('titles.events'))+" ")]),_c('MonthChanger')],1),_c('ul',{staticClass:"month-events__list"},_vm._l((_vm.monthEvents),function(collection,i){return _c('li',{key:i,class:{
                'month-events__item': true,
                'month-events__item_last': _vm.isLastItem(i),
            }},[_c('div',{class:{
                    'month-events__day': true,
                    'month-events__day_current': _vm.documentFiltersService.isCurrentDay(i + 1),
                },domProps:{"textContent":_vm._s(i + 1)}}),_c('EventsDayList',{attrs:{"holiday":collection.holiday,"my":collection.my,"suggested":collection.suggested,"chain":collection.chain}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }