
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class RatesGraphLabel extends Vue {
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(UserServiceS) private userService!: UserService;

    private disableHoteName: boolean = false;
    private disableCompsetType: boolean = false;

    get compsetType() {
        if (!this.compsetsService.currentCompset) {
            return null;
        }
        const { type } = this.compsetsService.currentCompset;

        return this.$t(`compset.${type}`);
    }

    get currentHotelName(): string | null {
        if (!this.userService.currentHotelId) {
            return null;
        }

        return this.hotelsService.getHotelName(this.userService.currentHotelId);
    }

    get currentHotelId(): number | null {
        return this.userService.currentHotelId;
    }

    toggleGraphLines(index: number) {
        if (index === 0) {
            this.disableHoteName = !this.disableHoteName;
        } else {
            this.disableCompsetType = !this.disableCompsetType;
        }
        this.$emit('toggleGraphData', index);
    }
}
